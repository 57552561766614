import React from "react";
import "./DevSix.css";
import { useLocation } from "react-router-dom";
import Navbar from "../../../navbar/Navbar";
import Caraousal from "../../project_details/caraousal/Caraousal";
import camSet1 from "../../../../images/Blaster/1_CameraSettings.jpg";
import camSet2 from "../../../../images/Blaster/1_CameraSettings_2.jpg";
import camSet3 from "../../../../images/Blaster/1_CameraSettings_3.jpg";
import camSet4 from "../../../../images/Blaster/1_CameraSettings_4.jpg";
import locoMo1 from "../../../../images/Blaster/1_Locomotion.jpg";
import locoMo2 from "../../../../images/Blaster/1_Locomotion_2.jpg";
import locoMo3 from "../../../../images/Blaster/1_Locomotion_3.jpg";
import locoMo4 from "../../../../images/Blaster/1_Locomotion_4.jpg";
import locoMo5 from "../../../../images/Blaster/1_Locomotion_5.jpg";
import locoMo6 from "../../../../images/Blaster/1_Locomotion_6.jpg";
import weapon1 from "../../../../images/Blaster/2_Weapon_1.jpg";
import weapon2 from "../../../../images/Blaster/2_Weapon_2.jpg";
import weapon3 from "../../../../images/Blaster/2_Weapon_3.jpg";
import weapon4 from "../../../../images/Blaster/2_Weapon_4.jpg";
import weapon5 from "../../../../images/Blaster/2_Weapon_5.jpg";
import weapon6 from "../../../../images/Blaster/2_Weapon_6.jpg";
import weapon7 from "../../../../images/Blaster/2_Weapon_7.jpg";
import weapon8 from "../../../../images/Blaster/2_Weapon_8.jpg";
import enemies1 from "../../../../images/Blaster/3_Enemies_1.jpg";
import enemies2 from "../../../../images/Blaster/3_Enemies_1.jpg";
import enemies3 from "../../../../images/Blaster/3_Enemies_1.jpg";
import enemies4 from "../../../../images/Blaster/3_Enemies_1.jpg";
import enemies5 from "../../../../images/Blaster/3_Enemies_1.jpg";
import enemies6 from "../../../../images/Blaster/3_Enemies_1.jpg";
import enemies7 from "../../../../images/Enemies/1.jpg";
import barrel1 from "../../../../images/Blaster/4_Barrel_1.jpg";
import barrel2 from "../../../../images/Blaster/4_Barrel_2.jpg";
import barrel3 from "../../../../images/Blaster/4_Barrel_3.jpg";
import barrel4 from "../../../../images/Blaster/4_Barrel_4.jpg";
import health1 from "../../../../images/Blaster/5_Health_1.jpg";
import health2 from "../../../../images/Blaster/5_Health_2.jpg";
import health3 from "../../../../images/Blaster/5_Health_3.jpg";

const DevSix = () => {
  const location = useLocation();
  const camSetList = [camSet1, camSet2, camSet3, camSet4];
  const locoMoList = [locoMo1, locoMo2, locoMo3, locoMo4, locoMo5, locoMo6];
  const weaponList = [
    weapon1,
    weapon2,
    weapon3,
    weapon4,
    weapon5,
    weapon6,
    weapon7,
    weapon8,
  ];
  const enemiesList = [
    enemies1,
    enemies2,
    enemies3,
    enemies4,
    enemies5,
    enemies6,
    enemies7,
  ];
  const barrelList = [barrel1, barrel2, barrel3, barrel4];
  const healthList = [health1, health2, health3];

  return (
    <div className="dev_five_container">
      <Navbar />
      <div className="main_section">
        <h1 className="game_title">{location.state.object.title}</h1>
        {/* <Caraousal props={imgList} /> */}
        <div className="dev-five-yt-player">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/Yo4miPfI7Pc`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          ></iframe>
        </div>
        <div>
          <h3>Role : {location.state.object.role}</h3>
          <h3>Team Members : {location.state.object.team_members}</h3>
        </div>
        <div className="dev_six_c_one">
          <h3>Project Overview</h3>
          <p>
            "Blaster" is an immersive third-person role-playing shooter game
            developed in Unreal Engine 4. As the sole developer, I created a
            complex game system that demonstrates advanced gameplay mechanics,
            intricate AI behavior, and a robust inventory system. The game
            follows a protagonist on a distant planet, tasked with retrieving a
            vital energy source while battling against hostile alien creatures.
          </p>
        </div>
        <div className="dev_six_c_two">
          <h3>Advanced Character Mechanics</h3>
          <p>
            This project introduced a robust character movement system that
            supports a wide array of actions, including strafing, crouching, and
            dynamic leaning. These mechanics allow for fluid, responsive motion
            in various gameplay scenarios. The animation system leveraged
            advanced Unreal Engine tools such as Animation Blueprints,
            Blendspaces, and Inverse Kinematics (IK), resulting in smooth,
            lifelike character animations. Additionally, the controls were
            optimized for both PC and console platforms, ensuring a seamless
            experience across different devices and input methods.
          </p>
        </div>
        <Caraousal props={camSetList} />
        <Caraousal props={locoMoList} />
        <div className="dev_six_c_three">
          <h3>Weapon Systems</h3>
          <p>
            The project featured a diverse array of weapon types, including
            pistols, submachine guns, and assault rifles, each with its own
            unique characteristics. A comprehensive inventory system allowed
            players to pick up, manage, and equip items through an intuitive UI.
            Efficient item data management was achieved using data tables and
            structures, while a rarity system provided visual feedback through
            distinct material effects. Realistic weapon mechanics were
            implemented, including recoil, reload animations, and particle
            effects such as muzzle flashes and bullet impacts. An adaptive
            crosshair system was also created, dynamically adjusting based on
            the player’s movement, weapon type, and firing state.
          </p>
        </div>
        <Caraousal props={weaponList} />
        <div className="dev_six_c_four">
          <h3>Explosive Barrels</h3>
          <p>
            The project also incorporated interactive explosive barrels, adding
            another layer of strategic depth to combat. These barrels could be
            detonated by bullets, creating a powerful explosion that dealt
            significant damage to nearby enemies. The explosion not only caused
            direct damage but also introduced tactical opportunities by
            affecting enemy behavior. If enemies were not killed by the blast,
            they would immediately enter an aggressive state and chase the
            player, intensifying the combat scenario. This dynamic element added
            a tactical option for players to manage groups of enemies or control
            the flow of battle. The explosive barrels were integrated with
            realistic physics and particle effects to enhance the visual and
            gameplay impact of the explosions
          </p>
        </div>
        <Caraousal props={barrelList} />
        <div className="dev_six_c_five">
          <h3>Enemy AI and Behavior Trees</h3>
          <p>
            Complex enemy AI was engineered using Behavior Trees and Blackboard
            Components, allowing for varied and dynamic combat encounters.
            Different enemy types were designed, each with unique attributes
            such as health, damage, size, and speed. The AI was further enhanced
            with patrol, aggro, chase, and attack patterns, creating a more
            engaging and reactive enemy behavior system. This variety in AI
            behavior helped shape more challenging and immersive combat
            scenarios for the player.
          </p>
        </div>
        <Caraousal props={enemiesList} />
        <div className="dev_six_c_six">
          <h3>Health Pickups</h3>
          <p>
            The project also included a health pickup system designed to restore
            the player's health, offering a vital resource during combat. These
            pickups came in various colors, with each color representing a
            different amount of health restoration. This allowed for a flexible
            design where different health pickups could replenish varying
            amounts of health, depending on the player's current needs or the
            level design. The visual distinction between health pickups made it
            easy for players to quickly identify and prioritize them during
            intense gameplay situations. This system added a strategic element,
            as players could plan their routes and tactics around the
            availability of health pickups to survive tough combat encounters.
          </p>
        </div>
        <Caraousal props={healthList} />
        <div>
          <h3>Visual Effects and Materials</h3>
          <p>
            Advanced material systems were created to elevate the visual
            experience and provide dynamic object interactions and visual
            feedback. Post-processing effects were implemented to enhance the
            game’s atmosphere, creating a more immersive environment.
            Additionally, particle systems were used to simulate environmental
            and combat-related effects, such as explosions and muzzle flashes,
            adding a sense of realism to the game world.
          </p>
        </div>

        <div>
          <h3>Sound Design</h3>
          <p>
            The sound design was another area of focus, with a wide range of
            effects integrated for weapons, character movement, and
            environmental ambiance. Footstep sounds were made dynamic, changing
            based on the surface material the character was walking on, further
            immersing the player in the game world. These sound effects added
            another layer of realism, enhancing the overall gameplay experience
          </p>
        </div>
        <div>
          <h3>Technical Proficiencies Demonstrated</h3>
          <p>
            This project demonstrated a strong proficiency in Unreal Engine 4,
            particularly with Blueprints and C++ programming. Expertise in
            animation systems was showcased through the use of Blendspaces,
            Inverse Kinematics, and Animation Blueprints. AI programming was
            highlighted with the use of Behavior Trees and Blackboard
            Components. UI/UX design and implementation were seamlessly
            integrated into the project, along with the creation of dynamic
            particle systems and visual effects. Sound design was thoroughly
            considered, along with performance optimization techniques to ensure
            smooth gameplay. These technical achievements were all grounded in
            solid game design principles.
          </p>
        </div>
        <div>
          <h3>Challenges and Solutions</h3>
          <p>
            One of the main challenges was creating a seamless blend between
            different animation states for the character. I overcame this by
            extensively using Animation Blueprints and carefully crafting
            transition rules between various states. This resulted in fluid
            character movements that respond naturally to player input and
            environmental factors.
          </p>
          <p>
            Another significant challenge was balancing the enemy AI to provide
            an engaging yet fair combat experience. I addressed this by
            implementing a scalable difficulty system within the Behavior Trees,
            allowing enemies to adapt their tactics based on the player's
            performance and game progression.
          </p>
        </div>
        <div>
          <h3>Conclusion</h3>
          <p>
            "Blaster" showcases my ability to create a complex, feature-rich
            game using Unreal Engine 4. Through this project, I've demonstrated
            proficiency in various aspects of game development, from low-level
            gameplay programming to high-level game design decisions. The skills
            and knowledge gained from this project have prepared me for tackling
            even more ambitious game development challenges in the future.
          </p>
        </div>
        <div className="dev-five-yt-player">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/4OPBcxpL0bc`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          ></iframe>
        </div>
        <div className="dev-five-yt-player">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed//BSkbrrcjPnM`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          ></iframe>
        </div>
        <div className="dev-five-yt-player">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/eJsGFen9-So`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default DevSix;
