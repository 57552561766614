import React from "react";
import "./DevOne.css";
import Navbar from "../../../navbar/Navbar";
import Caraousal from "../../project_details/caraousal/Caraousal";
import { useLocation } from "react-router-dom";
import level_1 from "../../../../images/dev_one/Level/1_1.jpg";
import level_2 from "../../../../images/dev_one/Level/1_2.jpg";
import level_3 from "../../../../images/dev_one/Level/1_3.jpg";
import level_4 from "../../../../images/dev_one/Level/1_4.jpg";
import level_5 from "../../../../images/dev_one/Level/1_5.jpg";
import level_6 from "../../../../images/dev_one/Level/1_6.jpg";
import level_7 from "../../../../images/dev_one/Level/1_7.jpg";
import code_1 from "../../../../images/dev_one/Codes/1C_1.png";
import code_2 from "../../../../images/dev_one/Codes/1C_2.png";
import code_3 from "../../../../images/dev_one/Codes/1C_3.png";
import code_4 from "../../../../images/dev_one/Codes/1C_4.png";
import code_5 from "../../../../images/dev_one/Codes/1C_5.png";
import code_6 from "../../../../images/dev_one/Codes/1C_6.png";
import code_7 from "../../../../images/dev_one/Codes/1C_7.png";
import code_8 from "../../../../images/dev_one/Codes/1C_8.png";
import code_9 from "../../../../images/dev_one/Codes/1C_9.png";
import ui_1 from "../../../../images/dev_one/UI/1U_1.1.png";
import ui_2 from "../../../../images/dev_one/UI/1U_1.png";
import ui_3 from "../../../../images/dev_one/UI/1U_2.1.png";
import ui_4 from "../../../../images/dev_one/UI/1U_2.2.png";
import ui_5 from "../../../../images/dev_one/UI/1U_2.3.png";
import ui_6 from "../../../../images/dev_one/UI/1U_2.png";
import ui_7 from "../../../../images/dev_one/UI/1U_3.1.png";
import ui_8 from "../../../../images/dev_one/UI/1U_3.2.png";
import ui_9 from "../../../../images/dev_one/UI/1U_3.3.png";
import ui_10 from "../../../../images/dev_one/UI/1U_3.4.png";
import ui_11 from "../../../../images/dev_one/UI/1U_3.png";
import ui_12 from "../../../../images/dev_one/UI/1U_4.1.png";
import ui_13 from "../../../../images/dev_one/UI/1U_4.png";
import ui_14 from "../../../../images/dev_one/UI/1U_5.1.png";
import ui_15 from "../../../../images/dev_one/UI/1U_5.png";

import Footer from "../../../footer/Footer";

const DevOne = () => {
  const location = useLocation();
  const imgList = [];
  const levelImgList = [
    level_1,
    level_2,
    level_3,
    level_4,
    level_5,
    level_6,
    level_7,
  ];
  const blueImgList = [
    code_1,
    code_2,
    code_3,
    code_4,
    code_5,
    code_6,
    code_7,
    code_8,
    code_9,
  ];
  const uiImgList = [
    ui_1,
    ui_1,
    ui_3,
    ui_4,
    ui_5,
    ui_6,
    ui_7,
    ui_8,
    ui_9,
    ui_10,
    ui_11,
    ui_12,
    ui_13,
    ui_14,
    ui_15,
  ];
  return (
    <div className="dev_one_container">
      <Navbar />
      <div className="main_section">
        <h1 className="game_title">{location.state.object.title}</h1>
        {/* <Caraousal props={levelImgList} /> */}
        <div className="dev-five-yt-player">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/f6WjNL7axGQ`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="Embedded youtube"
          ></iframe>
        </div>
        <div className="dev_four_roles roles-section">
          <h4>
            <span>Role : </span> {location.state.object.role}
          </h4>
          <h4>
            <span>Team Members : </span> {location.state.object.team_members}
          </h4>
        </div>
        <div className="tretio_details_container">
          <div className="dev_one_c_one tretio_container">
            <h3>Objectives and concepts</h3>
            <p>
              Players of my previous project, Tiretio, provided feedback to us,
              indicating that the game has a lot of potential if we keep
              improving and adding new features. First and foremost, an
              inventory system is a fundamental component of a game where
              players can equip various weapons, shields, and fruits to boost
              their health. It is also possible to add upgrades to swords and
              shields using various materials, and coins can be obtained in the
              inventory.
            </p>
          </div>
          <div className="dev_one_c_two tretio_container">
            <h3>Gameplay Overview:</h3>
            <ul>
              <li>
                Maze-like City: Navigate through a perilous maze-like city
                divided into four distinct sections
              </li>
              <li>
                Traps and Hazards: Encounter industrial fans, steam vents, and
                floating platforms, with the risk of falling into dangerous
                water regions.
              </li>
              <li>
                Coin Collection: Collect standard coins worth 100, 500, or 1000
                points, and specialized coins for speed boosts or extra time.
              </li>
              <li>
                Controls: Use W, A, S, and D keys for movement, space bar for
                jumping, and left-click to fire your weapon.
              </li>
              <li>
                Heads-Up Display (HUD): Track points, countdown timer, and
                health through a dynamic HUD
              </li>
              <li>
                Restart Option: If you die, restart from the beginning for a
                second chance at victory.
              </li>
              <li>
                End-Game UI: Displays total points upon game completion,
                showcasing your achievements.
              </li>
            </ul>
          </div>
          <div className="dev_one_c_three tretio_container">
            <h3>Platform and Engine:</h3>
            <ul>
              <li>Developed for PC using Unreal Engine 4.27.</li>
            </ul>
          </div>
          <div className="dev_one_c_four tretio_container">
            <h3>Technical Skills:</h3>
            <ul>
              <li>
                <span>Unreal Engine Blueprint:</span> Implemented player
                movement, health systems, coin collection, and interactive
                traps.
              </li>
              <li>
                <span>UI Blueprint Design:</span> UI Blueprint Design: Created a
                functional HUD displaying points, health, and timer using
                Blueprint structures.
              </li>
              <li>
                <span>Asset Integration:</span> Seamlessly integrated Epic
                Games' Marketplace assets for environments and game elements.
              </li>
              <li>
                <span>Custom 3D Modeling:</span> Custom 3D Modeling: Designed
                and modeled a unique weapon using Blender to replace the default
                gun.
              </li>
            </ul>
          </div>
          <div className="dev_one_c_five tretio_container">
            <h3>Challenges Faced:</h3>
            <ul>
              <li>
                <span>Unreal Interface Navigation:</span> Overcoming the
                complexity of Unreal Engine's interface to create game elements
                efficiently.
              </li>
              <li>
                <span>Performance Optimization:</span> Ensuring smooth gameplay
                performance by optimizing Blueprint logic.
              </li>
              <li>
                <span>Avoiding Infinite Loops:</span> Managed Blueprint logic to
                avoid unintended infinite loops and improve control flow.
              </li>
              <li>
                <span>Minimizing Response Time:</span> Ensured
                near-instantaneous feedback for player actions to enhance
                gameplay immersion.
              </li>
            </ul>
          </div>
          <div className="dev_one_c_six tretio_container">
            <h3>Further Development Ideas:</h3>
            <ul>
              <li>
                <span>Leaderboard Integration: </span> Leaderboard Integration:
                Add a competitive element by allowing players to compare scores.
              </li>
              <li>
                <span>Enemy AI:</span> Introduce enemies with AI behaviors to
                challenge players and add depth.
              </li>
              <li>
                <span>Weapon Upgrades:</span> Enable players to upgrade or
                unlock new weapons for varied gameplay.
              </li>
              <li>
                <span>Additional Levels:</span> Expand the game with more
                diverse and challenging levels.
              </li>
              <li>
                <span>Storyline Integration:</span>
                Incorporate a narrative to give the gameplay more context and
                purpose.
              </li>
              <li>
                <span>Power-ups and Abilities:</span>
                Introduce new power-ups or abilities to diversify gameplay
                strategies.
              </li>
              <li>
                <span>Visual and Audio Enhancements:</span> Improve textures,
                lighting, and sound effects to enhance the overall experience.
              </li>
            </ul>
          </div>
          <div className="dev_one_c_seven">
            <h2>Screenshots </h2>
            <h3>Levels : </h3>
            <Caraousal props={levelImgList} />
            <h3>Blueprints: </h3>
            <Caraousal props={blueImgList} />
            <h3>User Interface : </h3>
            <Caraousal props={uiImgList} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DevOne;
