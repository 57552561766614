import React from "react";
import Navbar from "../../../navbar/Navbar";
import Caraousal from "../../project_details/caraousal/Caraousal";
import { useLocation } from "react-router-dom";
import "./VrFour.css";
import vr_four_img_one from "../../../../images/cvc/CVC-1.jpg";
import vr_four_img_two from "../../../../images/cvc/CVC-2.jpg";
import vr_four_img_three from "../../../../images/cvc/CVC-3.jpg";
import vr_four_img_four from "../../../../images/cvc/CVC-4.jpg";
import vr_four_img_five from "../../../../images/cvc/CVC-L1.jpg";
import Footer from "../../../footer/Footer";

const VrFour = () => {
  const location = useLocation();
  const imgList = [
    vr_four_img_one,
    vr_four_img_two,
    vr_four_img_three,
    vr_four_img_four,
    vr_four_img_five,
  ];
  return (
    <div className="vr_four_container">
      <Navbar />
      <div className="main_section">
        <h1 className="game_title">{location.state.object.title}</h1>
        <Caraousal props={imgList} />
        <a
          href="https://medvr.education/solution/cvc-insertion-in-vr/"
          target="_blank"
        >
          Link to the video
        </a>
        <h3>
          <span style={{ color: "#03c988" }}>Role: </span>
          {location.state.object.role}
        </h3>

        <div className="head_toe_details_container">
          <div className="head_toe_c_two head_toe_details">
            <h3>Simulation Features: </h3>
            <ul>
              <li>
                <h4>Two Modes</h4>
                <ul>
                  <li>
                    <span>Learning Phase</span>
                    <ul>
                      <li>UI displays tasks with highlight markers.</li>
                    </ul>
                  </li>
                  <li>
                    <span>Assessment Phase</span>
                    <ul>
                      <li>No task UI or highlight markers</li>
                      <li>Progress UI was shown.</li>
                      <li>
                        3 opportunities to make mistakes; if all are used, a
                        restart is required.
                      </li>
                      <li>Correct task shown after errors.</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <h4>Multiplayer sessions</h4>
                <ul>
                  <li>
                    Multiple students can complete learning and assessment tasks
                    together.
                  </li>
                </ul>
              </li>
              <li>
                <h4>Menu Features:</h4>
                <ul>
                  <li>
                    Level selection, login, and VR simulation speed adjustment.
                  </li>
                </ul>
              </li>
              <li>
                <h4>Platform and Engine:</h4>
                <ul>
                  <li>
                    Developed for Meta Quest 2 using Unreal Engine 4.26 with
                    VaRest and Photon plugin.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="head_toe_c_two head_toe_details">
            <h3>Technical Implementations: </h3>
            <ul>
              <li>
                <span>Assistant NPCs: </span>Designed NPCs with different roles
                to perform tasks and communicate within the simulation.
              </li>
              <li>
                <span>Animation Blueprint: </span> Blended various bones to
                assess motor functions (arms, legs, reflexes) and achieve
                accurate patient poses.
              </li>
              <li>
                <span>Eye Movement Tracking: </span> Adjusted eye bone rotation
                based on player hand movement to assess the patient’s visual
                field.
              </li>
              <li>
                <span>Haptic Feedback: </span> Integrated for immersion,
                providing tactile feedback at appropriate moments.
              </li>
              <li>
                <span>Ambidextrous Grab Objects: </span> Handled objects like
                pencils, cotton swabs, and tuning forks with tags to determine
                collision type, trigger animations, and generate patient
                responses.
              </li>
              <li>
                <span>Multiplayer Session: </span> Enabled multiplayer, allowing
                multiple users to join as observers and view the host’s actions
                in real-time.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VrFour;
