import React from "react";
import "./DevThree.css";
import Navbar from "../../../navbar/Navbar";
import Caraousal from "../../project_details/caraousal/Caraousal";
import { useLocation } from "react-router-dom";
import charImage from "../../../../images/dev_three/Development/3D_1.jpg";
import locOne from "../../../../images/dev_three/Development/3D_2.0.jpg";
import locTwo from "../../../../images/dev_three/Development/3D_2.1.jpg";
import locThree from "../../../../images/dev_three/Development/3D_2.2.png";
import locFour from "../../../../images/dev_three/Development/3D_2.3.png";
import locFive from "../../../../images/dev_three/Development/3D_3.jpg";
import locSix from "../../../../images/dev_three/Development/3D_4.png";
import locSeven from "../../../../images/dev_three/Development/3D_5.png";
import locEight from "../../../../images/dev_three/Development/3D_6.png";
import locNine from "../../../../images/dev_three/Development/3D_7.png";
import fpOne from "../../../../images/dev_three/Development/3D_8.png";
import fpTwo from "../../../../images/dev_three/Development/3D_9.png";
import fpThree from "../../../../images/dev_three/Development/3D_10.png";
import chaAniOne from "../../../../images/dev_three/Development/3D_11.jpg";
import chaAniTwo from "../../../../images/dev_three/Development/3D_12.jpg";
import chaAniThree from "../../../../images/dev_three/Development/3D_13.jpg";
import chaAniFour from "../../../../images/dev_three/Development/3D_14.jpg";
import chaAniFive from "../../../../images/dev_three/Development/3D_15.jpg";
import chaAniSix from "../../../../images/dev_three/Development/3D_23.jpg";
import levOne from "../../../../images/dev_three/Level/3L_1.jpg";
import levTwo from "../../../../images/dev_three/Level/3L_2.jpg";
import levThree from "../../../../images/dev_three/Level/3L_3.jpg";
import levFour from "../../../../images/dev_three/Level/3L_4.jpg";
import levFive from "../../../../images/dev_three/Level/3L_5.jpg";
import levSix from "../../../../images/dev_three/Level/3L_6.jpg";
import levSeven from "../../../../images/dev_three/Level/3L_7.jpg";
import levEight from "../../../../images/dev_three/Level/3L_8.jpg";
import levNine from "../../../../images/dev_three/Level/3L_9.jpg";
import levTen from "../../../../images/dev_three/Level/3L_10.jpg";
import levEleven from "../../../../images/dev_three/Level/3L_11.jpg";
import puzOne from "../../../../images/dev_three/Level/3L_P_1.jpg";
import puzTwo from "../../../../images/dev_three/Level/3L_P_2.jpg";
import puzThree from "../../../../images/dev_three/Level/3L_P_3.jpg";
import puzFour from "../../../../images/dev_three/Level/3L_P_4.jpg";
import puzFive from "../../../../images/dev_three/Level/3L_P_5.jpg";
import puzSix from "../../../../images/dev_three/Level/3L_P_6.jpg";
import Footer from "../../../footer/Footer";

const DevThree = () => {
  const location = useLocation();
  const imgList = [];
  const levelImgList = [
    levOne,
    levTwo,
    levThree,
    levFour,
    levFive,
    levSix,
    levSeven,
    levEight,
    levNine,
    levTen,
    levEleven,
  ];
  const puzzelsImgList = [puzOne, puzTwo, puzThree, puzFour, puzFive, puzSix];
  const characterList = [charImage];
  const locoList = [
    locOne,
    locTwo,
    locThree,
    locFour,
    locFive,
    locSix,
    locSeven,
    locEight,
    locNine,
  ];
  const fpList = [fpOne, fpTwo, fpThree];
  const charActOne = [chaAniOne];
  const charActTwo = [chaAniTwo];
  const charActThree = [chaAniThree];
  const charActFour = [chaAniFour];
  const charActFive = [chaAniFive];
  const charActSix = [chaAniSix];

  return (
    <div className="dev_three_container">
      <Navbar />
      <div className="main_section">
        <h1 className="game_title">{location.state.object.title}</h1>
        {/* <Caraousal props={levelImgList} /> */}
        <div className="dev-five-yt-player">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/ZAQBx772OKM`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="Embedded youtube"
          ></iframe>
        </div>
        <div className="dev_four_roles roles-section">
          <h4>
            <span>Role : </span> {location.state.object.role}
          </h4>
          <h4>
            <span>Team Members : </span> {location.state.object.team_members}
          </h4>
        </div>
        <div className="amaze_details_container">
          <div className="dev_three_c_one amaze_container">
            <h3>Objectives and concepts</h3>
            <p>
              Players of my previous project, Amaze, provided feedback to us,
              indicating that the game has a lot of potential if we keep
              improving and adding new features. First and foremost, an
              inventory system is a fundamental component of a game where
              players can equip various weapons, shields, and fruits to boost
              their health. It is also possible to add upgrades to swords and
              shields using various materials, and coins can be obtained in the
              inventory.
            </p>
          </div>
          <div className="dev_three_c_two amaze_container">
            <h3>Core Features</h3>
            <ul>
              <li>
                "AMAZE" is a PC-based game built on Unreal Engine 4.27, where
                players assume the role of Echo, an archaeology student.
              </li>
              <li>
                The goal is to navigate mazes, solve puzzles, combat enemies,
                and find a hidden artifact.
              </li>
              <li>
                The game is divided into four distinct parts:
                <ul>
                  <li>
                    The goal is to navigate mazes, solve puzzles, combat
                    enemies, and find a hidden artifact.
                  </li>
                  <li>
                    Dynamic Maze Challenge: Navigate moving walls and reach an
                    underground well.
                  </li>
                  <li>
                    Combat and Exploration: Fight enemies, destroy objects, and
                    survive deadly fan challenges.
                  </li>
                  <li>
                    Final Level: Solve two out of four puzzles in a two-story
                    building to reach the true artifact.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="dev_three_c_three amaze_container">
            <h3>Character and Controls:</h3>
            <ul>
              <li>
                Character: Echo, a versatile Wind Walker, equipped with jumping,
                combat, and item collection skills.
              </li>
              <li>
                Controls
                <ul>
                  <li>W, A, S, D: Movement.</li>
                  <li>Mouse: Camera direction.</li>
                  <li>
                    Left/Right-click: Light and heavy attacks with a sword.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="dev_three_c_four amaze_container">
            <h3>Key Game Features:</h3>
            <ul>
              <li>
                Puzzles and Exploration: Diverse challenges with hidden clues,
                unlocking deeper levels.
              </li>
              <li>
                Combat: Engage in melee combat with light/heavy attacks; enemies
                react dynamically to Echo's presence.
              </li>
              <li>
                Checkpoints: Allow respawning after failure, ensuring player
                progression.
              </li>
            </ul>
          </div>
          <div className="dev_three_c_five amaze_container">
            <h3>Technical Skills Highlighted:</h3>
            <ul>
              <li>
                Unreal Engine C++: Implemented core game mechanics, enemy
                behaviors, and character attributes.
              </li>
              <li>
                Blueprint Scripting: Created intricate puzzles and interactions
                using visual scripting.
              </li>
              <li>
                Character Animation: Integrated complex animation montages for
                combat and movement using Unreal Engine's blend space, state
                machines.
              </li>
              <li>
                Collision Detection: Utilized box tracing for accurate hit
                detection and dynamic interactions (e.g., breaking objects).
              </li>
              <li>
                3D Modeling (Blender): Designed maze structures, and optimized
                models with UV unwrapping and 2K textures.
              </li>
              <li>
                UI Design: Created in-game HUD elements, including timers,
                counters, and hints.
              </li>
            </ul>
          </div>
          <div className="dev_three_c_six amaze_container">
            <h3>Development Challenges:</h3>
            <ul>
              <li>
                Time Constraints: Developed the game within tight deadlines for
                a course and Gotland Game conference.
              </li>
              <li>
                Transition from Board Games: Shifted from designing board games
                to developing a 3D third-person game.
              </li>
              <li>
                Bug Solving and Testing: Rigorous testing and debugging were
                essential for feature implementation.
              </li>
              <li>
                Performance Optimization: Ensured smooth game performance with
                resource-efficient management.
              </li>
              <li>
                Team Collaboration: Effective communication among team members
                with limited game development experience.
              </li>
            </ul>
          </div>
          <div className="dev_three_c_seven amaze_container">
            <h3>Achievements:</h3>
            <ul>
              <li>
                Nomination: People’s Choice Award at Gotland Game Conference.
              </li>
              <li>
                Course Distinction: Passed the course with distinction for the
                game's successful development.
              </li>
              <li>
                Player Feedback: Positive reviews on complexity, design, and
                gameplay, alongside valuable constructive criticism for growth.
              </li>
            </ul>
          </div>
          <div className="dev_three_c_eight amaze_container">
            <h3>Lessons Learned:</h3>
            <ul>
              <li>
                Proper Planning & Documentation: Clear objectives, deadlines,
                and thorough documentation are crucial for project{" "}
              </li>
              <li>
                Working Under Pressure: Managing tight deadlines sharpened time
                management skills and the ability to produce high-quality work
                under pressure.
              </li>
              <li>
                Problem-Solving Dedication: Tackling complex challenges taught
                the value of persistence and creative problem-solving, key to
                overcoming obstacles in game programming.
              </li>
              <li>
                Enjoying the Creative Process: Embracing the creative journey
                fosters motivation and innovation, leading to more fulfilling
                and engaging work experiences.
              </li>
              <li>
                Team Collaboration & Connections: Strong teamwork, effective
                communication, and mutual support were essential in overcoming
                challenges and maintaining a harmonious development environment.
              </li>
            </ul>
          </div>
          <div className="dev_three_c_nine">
            <h3>Screenshots : </h3>
            <div>
              <h3>Levels : </h3>
              <Caraousal props={levelImgList} />
            </div>
            <div>
              <h3>Puzzels : </h3>
              <Caraousal props={puzzelsImgList} />
            </div>
            <div className="d_three_bp_container">
              <h3>BluePrints and Coding: </h3>
              <h4>Character : </h4>
              <Caraousal props={characterList} />
              <h4>Locomotion : </h4>
              <Caraousal props={locoList} />
              <h4>Foot Placement : </h4>
              <Caraousal props={fpList} />
            </div>
            <div className="dev_three_ol_container">
              <h4>Character Animation Montages : </h4>
              <ol className="dev_three_ol">
                <li>
                  <p className="dev_three_ol_p">Attack : </p>
                  <Caraousal props={charActOne} />
                </li>
                <li>
                  <p>Death : </p>
                  <Caraousal props={charActTwo} />
                </li>
                <li>
                  <p>Equip/Unequip : </p>
                  <Caraousal props={charActThree} />
                </li>
                <li>
                  <p>Hit React : </p>
                  <Caraousal props={charActFour} />
                </li>
                <li>
                  <p>Retargetter : </p>
                  <Caraousal props={charActFive} />
                </li>
                <li>
                  <p>Enemy : </p>
                  <Caraousal props={charActSix} />
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DevThree;
