import React from "react";
import "./DevFour.css";
import Navbar from "../../../navbar/Navbar";
import Caraousal from "../../project_details/caraousal/Caraousal";
import { useLocation } from "react-router-dom";
import Footer from "../../../footer/Footer";

import inventoryOne from "../../../../images/Inventory/Inventory/1.jpg";
import inventoryTwo from "../../../../images/Inventory/Inventory/2.jpg";
import inventoryThree from "../../../../images/Inventory/Inventory/3.jpg";
import inventoryFour from "../../../../images/Inventory/Inventory/4.jpg";
import inventoryFive from "../../../../images/Inventory/Inventory/5.jpg";
import inventorySix from "../../../../images/Inventory/Inventory/6.jpg";
import inventorySeven from "../../../../images/Inventory/Inventory/7.jpg";

import dtOne from "../../../../images/Inventory/DataTables/1.jpg";
import dtTwo from "../../../../images/Inventory/DataTables/2.jpg";

import bpcInvOne from "../../../../images/Inventory/BPC_inventory/1.jpg";
import bpcInvTwo from "../../../../images/Inventory/BPC_inventory/2.jpg";
import bpcInvThree from "../../../../images/Inventory/BPC_inventory/3.jpg";
import bpcInvFour from "../../../../images/Inventory/BPC_inventory/4.jpg";
import bpcInvFive from "../../../../images/Inventory/BPC_inventory/5.jpg";

import wbpInvOne from "../../../../images/Inventory/WBP_Inventory/0.jpg";
import wbpInvTwo from "../../../../images/Inventory/WBP_Inventory/1.jpg";
import wbpInvThree from "../../../../images/Inventory/WBP_Inventory/2.jpg";
import wbpInvFour from "../../../../images/Inventory/WBP_Inventory/3.jpg";
import wbpInvFive from "../../../../images/Inventory/WBP_Inventory/4.jpg";
import wbpInvSix from "../../../../images/Inventory/WBP_Inventory/5.jpg";

import wbpHBOne from "../../../../images/Inventory/WBP_HealthBar/1.jpg";

import wbpInfoOne from "../../../../images/Inventory/WBP_Info/0.jpg";
import wbpInfoTwo from "../../../../images/Inventory/WBP_Info/1.jpg";
import wbpActionOne from "../../../../images/Inventory/WBP_Action/0.jpg";
import wbpActionTwo from "../../../../images/Inventory/WBP_Action/1.jpg";
import wbpActionThree from "../../../../images/Inventory/WBP_Action/2.jpg";
import wbpActionFour from "../../../../images/Inventory/WBP_Action/3.jpg";
import wbpActionFive from "../../../../images/Inventory/WBP_Action/4.jpg";
import wbpActionSix from "../../../../images/Inventory/WBP_Action/5.jpg";

import wbpSlotOne from "../../../../images/Inventory/WBP_Slot/0.jpg";
import wbpSlotTwo from "../../../../images/Inventory/WBP_Slot/1.jpg";
import wbpSlotThree from "../../../../images/Inventory/WBP_Slot/2.jpg";
import wbpSlotFour from "../../../../images/Inventory/WBP_Slot/3.jpg";
import wbpSlotFive from "../../../../images/Inventory/WBP_Slot/4.jpg";
import wbpSlotSix from "../../../../images/Inventory/WBP_Slot/5.jpg";
import wbpSlotSeven from "../../../../images/Inventory/WBP_Slot/6.jpg";

const DevFour = () => {
  const location = useLocation();
  const inventoryImgList = [
    inventoryOne,
    inventoryTwo,
    inventoryThree,
    inventoryFour,
    inventoryFive,
    inventorySix,
    inventorySeven,
  ];
  const dtImgList = [dtOne, dtTwo];
  const bpcInvImgList = [
    bpcInvFive,
    bpcInvFour,
    bpcInvThree,
    bpcInvTwo,
    bpcInvOne,
  ];
  const wbcInvImgList = [
    wbpInvOne,
    wbpInvTwo,
    wbpInvThree,
    wbpInvFour,
    wbpInvFive,
    wbpInvSix,
  ];
  const hbImgList = [wbpHBOne];
  const wbpInfoImgList = [wbpInfoOne, wbpInfoTwo];
  const wbpActionImgList = [
    wbpActionOne,
    wbpActionTwo,
    wbpActionThree,
    wbpActionFour,
    wbpActionFive,
    wbpActionSix,
  ];
  const wbpSlotImgList = [
    wbpSlotOne,
    wbpSlotTwo,
    wbpSlotThree,
    wbpSlotFour,
    wbpSlotFive,
    wbpSlotSix,
    wbpSlotSeven,
  ];

  return (
    <div className="dev_four_container">
      <Navbar />
      <div className="main_section">
        <h1 className="game_title">{location.state.object.title}</h1>
        {/* <Caraousal props={inventoryImgList} /> */}
        <div className="dev-five-yt-player">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/X1nq5mhnwxo`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="Embedded youtube"
          ></iframe>
        </div>
        <div className="dev_four_roles roles-section">
          <h4>
            <span>Role : </span> {location.state.object.role}
          </h4>
          <h4>
            <span>Team Members : </span> {location.state.object.team_members}
          </h4>
        </div>
        <div className="dev_four_details">
          <div className="dev_four_c_one inventory_container">
            <h3>Objectives and concepts</h3>
            <p>
              Players of my previous project, Amaze, provided feedback to us,
              indicating that the game has a lot of potential if we keep
              improving and adding new features. First and foremost, an
              inventory system is a fundamental component of a game where
              players can equip various weapons, shields, and fruits to boost
              their health. It is also possible to add upgrades to swords and
              shields using various materials, and coins can be obtained in the
              inventory.
            </p>
          </div>
          <div className="dev_four_c_two inventory_container">
            <h3>Core Features</h3>
            <ul>
              <li>Players manage swords, shields, and consumables</li>
              <li>
                Equip or use items via double left-click, right-click opens
                action menu (use, equip, drop)
              </li>
              <li>
                Drag-and-drop functionality for item rearrangement and removal
              </li>
              <li>Real-time 3D character display within inventory</li>
            </ul>
          </div>
          <div className="dev_four_c_three inventory_container">
            <h3>Development</h3>
            <ul>
              <li>Built-in Unreal Engine 5.3.</li>
              <li>
                <span>Blueprint Actors: </span> Used for money and item
                handling, with data tables for item/money attributes
              </li>
              <li>
                <span>Blueprint Component (BPC_Inventory): </span> Modular
                inventory logic for reusability in other projects
              </li>
              <li>
                <span>Enumerations: </span> Categorized items into swords,
                shields, and food
              </li>
              <li>
                <span>Structures: </span> Managed money, item data, and slot
                contents for data tables
              </li>
              <li>
                <span>Widget Blueprints: </span> Managed the user interface, key
                to system functionality
              </li>
            </ul>
          </div>
          <div className="dev_four_c_four inventory_container">
            <h3>Challenges</h3>
            <ul>
              <li>Coordinating multiple widget interactions</li>
              <li>Managing data arrays for item collection/disposal</li>
              <li>Designing icons without strong Photoshop skills</li>
            </ul>
          </div>
          <div className="dev_four_c_five inventory_container">
            <h3>Future Development</h3>
            <ul>
              <li>
                Integrating weapon upgrades and testing animations for equipped
                items
              </li>
            </ul>
          </div>

          <div className="dev_four_c_six dev_carousel">
            <h3>Screenshots: </h3>
            <ul>
              <li>
                <h4>DataTables: </h4>
                <Caraousal props={dtImgList} />
              </li>
              <li>
                <h4>BPC Inventory: </h4>
                <Caraousal props={bpcInvImgList} />
              </li>
              <li>
                <h4>WBP Inventory: </h4>
                <Caraousal props={wbcInvImgList} />
              </li>
              <li>
                <h4>WBP HealthBar: </h4>
                <Caraousal props={hbImgList} />
              </li>
              <li>
                <h4>WBP Info: </h4>
                <Caraousal props={wbpInfoImgList} />
              </li>
              <li>
                <h4>WBP Action: </h4>
                <Caraousal props={wbpActionImgList} />
              </li>
              <li>
                <h4>WBP Slot: </h4>
                <Caraousal props={wbpSlotImgList} />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DevFour;
