import React from "react";
import "./VrOne.css";
import { useLocation } from "react-router-dom";
import Navbar from "../../../navbar/Navbar";
import Caraousal from "../../project_details/caraousal/Caraousal";
import vr_one_img_one from "../../../../images/trachiostomy/TC-2.jpg";
import vr_one_img_two from "../../../../images/trachiostomy/TC-3.jpg";
import vr_one_img_three from "../../../../images/trachiostomy/TC-4.jpg";
import vr_one_img_four from "../../../../images/trachiostomy/TC-5.jpg";
import vr_one_img_five from "../../../../images/trachiostomy/TC-6.jpg";
import vr_one_img_six from "../../../../images/trachiostomy/TC-7.jpg";
import Footer from "../../../footer/Footer";
const VrOne = () => {
  const location = useLocation();
  const imgList = [
    vr_one_img_one,
    vr_one_img_two,
    vr_one_img_three,
    vr_one_img_four,
    vr_one_img_five,
    vr_one_img_six,
  ];

  return (
    <div className="vrone_container">
      <Navbar />
      <div className="main_section">
        <h1 className="game_title">{location.state.object.title}</h1>
        {/* <Caraousal props={imgList} /> */}
        <div className="dev-five-yt-player">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/VjHQ-5Pevos?si=4PUqs9WbB2uo2mhG`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="Embedded youtube"
          ></iframe>
        </div>
        {/* <a href="https://medvr.education/solution/tracheostomy-care/">
          https://medvr.education/solution/tracheostomy-care/
        </a> */}
        <h3>
          <span style={{ color: "#03c988" }}>Role: </span>
          {location.state.object.role}
        </h3>
        <div className="trach_details_container">
          <div className="trach_c_one trach_details">
            <h3>Simulation Features: </h3>
            <ul>
              <li>
                <h4>Two Modes</h4>
                <ul>
                  <li>
                    <span>Learning Phase</span>
                    <ul>
                      <li>UI displays tasks with highlight markers.</li>
                    </ul>
                  </li>
                  <li>
                    <span>Assessment Phase</span>
                    <ul>
                      <li>No task UI or highlight markers</li>
                      <li>Progress UI was shown.</li>
                      <li>
                        3 opportunities to make mistakes; if all are used, a
                        restart is required.
                      </li>
                      <li>Correct task shown after errors.</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <h4>Multiplayer sessions</h4>
                <ul>
                  <li>
                    Multiple students can complete learning and assessment tasks
                    together.
                  </li>
                </ul>
              </li>
              <li>
                <h4>Menu Features:</h4>
                <ul>
                  <li>
                    Level selection, login, and VR simulation speed adjustment.
                  </li>
                </ul>
              </li>
              <li>
                <h4>Platform and Engine:</h4>
                <ul>
                  <li>
                    Developed for Meta Quest 2 using Unreal Engine 4.26 with
                    VaRest and Photon plugin.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="trach_c_two trach_details">
            <h3>Technical Implementations: </h3>
            <ul>
              <li>
                <span>Grabbing Mechanics: </span> Implemented from the company's
                pipeline architecture, integrated with object highlighting and
                guiding UI for clarity.
              </li>
              <li>
                <span>Collision Handling: </span> Extensively used box, sphere,
                and capsule collisions for overlapping, attachment, and
                detachment of objects.
              </li>
              <li>
                <span>Particle Effects: </span> Scaled particle effects to
                simulate water, gels, sanitizer, and blood interactions.
              </li>
              <li>
                <span>Timeline Animations: </span> Utilized timelines for
                animating box covers, drapes, and adjusting bed height/rotation.
              </li>
              <li>
                <span>Custom Movement Calculation: </span> Used mathematical
                calculations and timer events for precise object
                rotation/translation based on player hand movements.
              </li>
              <li>
                <span>Reusable Blueprints: </span> Created blueprints with
                public parameters for flexibility and reuse.
              </li>
              <li>
                <span>Dynamic Meter Logic: </span> Implemented meter systems
                allowing players to alter values, causing real-time patient
                reactions via colliders and runtime UI updates.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VrOne;
