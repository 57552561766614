import React from "react";
import "./ProjectDetails.css";
import Caraousal from "./caraousal/Caraousal";
import { useLocation } from "react-router-dom";
import Navbar from "../../navbar/Navbar";
import atIcon from "../../../images/at_icon.svg";
import twitter from "../../../images/t_icon.svg";
import github from "../../../images/git_icon.svg";
import insta from "../../../images/instagram.svg";

const ProjectDetails = (details) => {
  const location = useLocation();

  // Destructure the object from location.state
  const {
    id,
    title,
    game_description,
    carousal_imageList,
    genre,
    features,
    development_steps,
    instructions,
  } = location.state.object;

  // Prepare image list for carousel
  var imgList = [];
  carousal_imageList.map((img) => imgList.push(img));

  return (
    <div className="project_details_container">
      <Navbar />
      <div className="main_section">
        <h1 className="game_title">{title}</h1>
        <Caraousal props={imgList} />
        <div className="game_description">{game_description}</div>
        <div className="genre_features_section">
          <div className="genre_section">
            <h4 className="genre_title tit">Genre</h4>
            <p className="genre_values des">{genre || "N/A"}</p>{" "}
            {/* Replace genre with object field */}
          </div>
          <div className="features_section">
            <h4 className="feature_title tit">Features</h4>
            <p className="des">{features || "N/A"}</p>{" "}
            {/* Replace features with object field */}
          </div>
        </div>
        <div className="development_details">
          <h3 className="sec_title">Development Details</h3>
          <div className="steps_section">
            <div className="initial_steps">
              <div className="initial_steps_text">
                <ul className="steps_text">
                  {development_steps?.initial?.map((step, index) => (
                    <li key={index}>{step}</li>
                  )) || <li>No initial steps provided</li>}
                </ul>
              </div>
            </div>
            <div className="final_steps">
              <div className="final_steps_text">
                <ul className="steps_text">
                  {development_steps?.final?.map((step, index) => (
                    <li key={index}>{step}</li>
                  )) || <li>No final steps provided</li>}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="game_instructions_section">
          <h2 className="game_instructions_title">Game Instructions</h2>
          <div className="game_instructions_text_section">
            <ul className="game_instructions_text">
              {instructions?.map((instruction, index) => (
                <li key={index}>
                  <p>{instruction}</p>
                </li>
              )) || <li>No instructions available</li>}
            </ul>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footer_icons">
          <img className="footer_icon" src={atIcon} alt="Contact" />
          <img className="footer_icon" src={twitter} alt="Twitter" />
          <img className="footer_icon" src={github} alt="GitHub" />
          <img className="footer_icon" src={insta} alt="Instagram" />
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
