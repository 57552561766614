import React from "react";
import { useLocation } from "react-router-dom";
import "./DevTwo.css";
import Navbar from "../../../navbar/Navbar";
import Caraousal from "../../project_details/caraousal/Caraousal";
import lev_1 from "../../../../images/dev_two/Levels/2_1.png";
import lev_2 from "../../../../images/dev_two/Levels/2_2.png";
import lev_3 from "../../../../images/dev_two/Levels/2_3.png";
import blu_1 from "../../../../images/dev_two/Development/2C_1.png";
import blu_2 from "../../../../images/dev_two/Development/2C_2.png";
import blu_3 from "../../../../images/dev_two/Development/2C_3.png";
import blu_4 from "../../../../images/dev_two/Development/2C_4.png";
import blu_5 from "../../../../images/dev_two/Development/2C_5.png";
import blu_6 from "../../../../images/dev_two/Development/2C_6.png";
import blu_7 from "../../../../images/dev_two/Development/2C_8.png";
import blu_8 from "../../../../images/dev_two/Development/2C_9.png";
import blu_9 from "../../../../images/dev_two/Development/2C_10.png";
import blu_10 from "../../../../images/dev_two/Development/2C_11.png";
import blu_11 from "../../../../images/dev_two/Development/2C_12.png";
import blu_12 from "../../../../images/dev_two/Development/2C_13.png";
import blu_13 from "../../../../images/dev_two/Development/2C_14.png";
import blu_14 from "../../../../images/dev_two/Development/2C_15.png";
import blu_15 from "../../../../images/dev_two/Development/2C_16.png";
import blu_16 from "../../../../images/dev_two/Development/2C_17.png";
import blu_17 from "../../../../images/dev_two/Development/2C_18.png";
import blu_18 from "../../../../images/dev_two/Development/2C_19.png";
import blu_19 from "../../../../images/dev_two/Development/2C_20.png";
import ui_1 from "../../../../images/dev_two/UI/2U_1.png";
import ui_2 from "../../../../images/dev_two/UI/2U_2.png";
import ui_3 from "../../../../images/dev_two/UI/2U_3.png";
import ui_4 from "../../../../images/dev_two/UI/2U_4.png";
import ui_5 from "../../../../images/dev_two/UI/2U_5.png";
import ui_6 from "../../../../images/dev_two/UI/2U_6.png";
import ui_7 from "../../../../images/dev_two/UI/2U_7.png";
import ui_8 from "../../../../images/dev_two/UI/2U_8.png";
import ui_9 from "../../../../images/dev_two/UI/2U_9.png";
import ui_10 from "../../../../images/dev_two/UI/2U_10.png";
import Footer from "../../../footer/Footer";

const DevTwo = () => {
  const location = useLocation();
  const imgList = [];
  const levelImgList = [lev_1, lev_2, lev_3];
  const bluePrintImgList = [
    blu_1,
    blu_2,
    blu_3,
    blu_4,
    blu_5,
    blu_6,
    blu_7,
    blu_8,
    blu_9,
    blu_10,
    blu_11,
    blu_12,
    blu_13,
    blu_14,
    blu_15,
    blu_16,
    blu_17,
    blu_18,
    blu_19,
  ];
  const uiImgList = [
    ui_1,
    ui_2,
    ui_3,
    ui_4,
    ui_5,
    ui_6,
    ui_7,
    ui_8,
    ui_9,
    ui_10,
  ];
  return (
    <div className="dev_two_container">
      <Navbar />
      <div className="main_section">
        <h1 className="game_title">{location.state.object.title}</h1>
        {/* <Caraousal props={levelImgList} /> */}
        <div className="dev-five-yt-player">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/0yMhxjao0Fo`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="Embedded youtube"
          ></iframe>
        </div>
        <div className="dev-five-yt-player">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/zntVX7zbgWU`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="Embedded youtube"
          ></iframe>
        </div>
        <div className="dev_four_roles roles-section">
          <h4>
            <span>Role : </span> {location.state.object.role}
          </h4>
          <h4>
            <span>Team Members : </span> {location.state.object.team_members}
          </h4>
        </div>
        <div className="pacman_details_container">
          <div className="dev_two_c_one pacman_container">
            <h3>Concept:</h3>
            <ul>
              <li>
                Reimagined Pac-Man in 3D:A modern, 3D adaptation of the classic
                Pac-Man arcade game, featuring larger maps, additional enemies,
                and power-up points. Orthographic camera angles and detailed 3D
                wall models enhance the immersive gameplay.
              </li>
              <li>
                <span>Player Mission:</span>Explore the maze and collect points
                and power-ups while avoiding enemies. Players use teleportation
                mechanisms to move quickly across the map, adding strategic
                depth.
              </li>
              <li>
                <span>Modern UI:</span>The game retains classic elements like
                score display and remaining lives but presents them in a modern,
                visually appealing user interface.
              </li>
            </ul>
          </div>
          <div className="dev_two_c_two pacman_container">
            <h3>Gameplay Overview:</h3>
            <ul>
              <li>
                Control and Movement: The player controls a constantly moving
                character ("pawn") with W, A, S, and D keys to navigate the
                maze.
              </li>
              <li>
                Objective: Collect points and power-ups across three
                progressively harder levels while avoiding enemies. The levels
                loop gradually as the game continues.
              </li>
              <li>
                Points and Power-Ups: Collect 1000-point items (fruits like
                berries, oranges, and donuts). Donuts provide a speed boost, and
                only one fruit spawns per level randomly.
              </li>
              <li>
                Teleportation Mechanism: Teleport from one side of the maze to
                the other to evade enemies or reach points quickly.
              </li>
              <li>
                Enemy Types: Two enemy types:
                <ul>
                  <li>Wanderers: Move aimlessly.</li>
                  <li>
                    Hunters: Actively pursue the player. Enemy numbers increase
                    with each level, culminating in six enemies (three of each
                    type) in the final stage.
                  </li>
                </ul>
              </li>
              <li>
                Power-Up Points & Enemy Vulnerability: Consuming a power-up
                renders enemies vulnerable, allowing players to temporarily eat
                them for increasing points (200, 400, 800, 1600).
              </li>
              <li>
                Lives and Extra Lives: Players start with a set number of lives
                and earn extra lives at specific point milestones.
              </li>
              <li>
                Respawning Mechanics:
                <ul>
                  <li>
                    When touched by an enemy, players and enemies respawn at
                    original locations.
                  </li>
                  <li>
                    Vulnerable enemies respawn in the house and follow their
                    behavior (wandering/hunting) after a wait period.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="dev_two_c_three pacman_container">
            <h3>Development</h3>
            <ul>
              <li>Developed for PC using Unreal Engine 4.27</li>
            </ul>
          </div>
          <div className="dev_two_c_four pacman_container">
            <h3>Technical Skills:</h3>
            <ul>
              <li>
                Blueprint Programming: Proficient in Unreal Engine's blueprint
                system to develop game logic, player interaction, and enemy
                behaviors.
              </li>
              <li>
                Level Design: Skilled in designing intricate mazes using box
                brushes and custom materials.
              </li>
              <li>
                Geometry Creation: Expertise in using boolean cutters for
                complex character and enemy shapes in Unreal Engine.
              </li>
              <li>
                Asset Integration: Integrated marketplace assets like fruits and
                power-ups to enhance visual elements.
              </li>
              <li>
                Material Customization: Proficient in customizing materials for
                walls and game objects to create a cohesive aesthetic.
              </li>
              <li>
                UI Design (Widget Blueprints): Designed UI elements like score
                displays, health bars, start menus, pause menus, game-over
                screens, leaderboards, and level-completion menus.
              </li>
              <li>
                Game Instance Management: Used Unreal Engine's game instance for
                data persistence, such as leaderboard tracking across levels.
              </li>
              <li>
                Game Logic and Functionality: Implemented consistent game
                mechanics using game modes and advanced logic, ensuring seamless
                player experience.
              </li>
              <li>
                Animation & Interactivity: Used flip-flop methods for
                controlling dynamic actions, such as gobbling points and
                transitioning game states.
              </li>
            </ul>
          </div>
          <div className="dev_two_c_five pacman_container">
            <h3>Challenges Faced:</h3>
            <ul>
              <li>
                Gobbling Character and Enemy Types: Balancing engaging gameplay
                with tw
              </li>
              <li>
                Self-Learning in Unreal Engine: Overcoming challenges involved
                extensive research, online tutorials, and continuous learning to
                master Unreal Engine's tools.
              </li>
              <li>
                Save Structure and Leaderboard: Implementing a save system and a
                leaderboard to track player progress and scores involved complex
                data management.
              </li>
              <li>
                Sound Design: Selecting and aligning audio assets to match the
                game's atmosphere was critical to enhancing immersion.
              </li>
              <li>
                Point Structure Management: Ensuring seamless point accumulation
                and game continuity required precise control over scoring and
                progression mechanics.
              </li>
            </ul>
          </div>
          <div className="dev_two_c_six pacman_container">
            <h3>Lessons Learned</h3>
            <ul>
              <li>
                Thorough Planning & Documentation: Proper project planning and
                detailed documentation are crucial for staying organized and
                efficient.
              </li>
              <li>
                Time Management & Pressure Handling: Tight deadlines sharpened
                time management and the ability to deliver quality work under
                pressure.
              </li>
              <li>
                Problem-Solving Dedication: Tackling difficult issues enhanced
                perseverance and problem-solving skills, which are essential in
                game development.
              </li>
              <li>
                Creative Enjoyment: The development process reinforced the
                importance of enjoying creative work, as it fosters innovation
                and motivation.
              </li>
              <li>
                Effective Team Collaboration: Strong communication and teamwork
                proved key in overcoming obstacles and maintaining a positive
                development atmosphere.
              </li>
            </ul>
          </div>
          <div className="dev_two_c_seven">
            <h2>Screenshots : </h2>
            <h3>Levels : </h3>
            <Caraousal props={levelImgList} />
            <h3>Blueprint : </h3>
            <Caraousal props={bluePrintImgList} />
            <h3>User Interface : </h3>
            <Caraousal props={uiImgList} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DevTwo;
